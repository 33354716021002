<template>
  <div class="app-header">
    <a-row type="flex">
      <a-col
        class="app-header__wrapper app-header__wrapper--mobile"
        :xs="24"
        :sm="0"
      >

 
        <a-col :xs="24">
          <div class="app-header__left">
            <a-button
              class="app-header__hamburger"
              type="link"
              @click="visibleMobileMenu = true"
              shape="round"
            >
              <template v-slot:icon><MenuOutlined /></template>
            </a-button>
            <router-link to="/">
              <div class="app-header__logo"></div>
            </router-link>
            <a-drawer
              placement="left"
              :closable="true"
              class="app-header__drawer"
              v-model:visible="visibleMobileMenu"
            >
              <div v-if="!store.user.accessToken" class="app-header__nav">
                <nav>
                  <a-button @click="changeRoute('/contact')" type="link">
                    Kontakt
                  </a-button>

                  <a-button
                    @click="changeRoute('/login')"
                    class="app-header__login"
                    type="link"
                  >
                    Zaloguj się
                  </a-button>
                </nav>
              </div>
              <div v-if="store.user.accessToken" class="app-header__nav">
                <nav v-if="store.user.roles.includes('PATIENT')">
                  <a-button @click="changeRoute('/')" type="link">
                    Moje wizyty
                  </a-button>

                  <a-button @click="changeRoute('/profile')" type="link">
                    Dane konta
                  </a-button>

                  <a-button
                    class="app-header__logout"
                    @click="logout"
                    type="link"
                  >
                    Wyloguj się
                  </a-button>
                </nav>
                <nav v-if="store.user.roles.includes('DOCTOR')">
                  <a-button
                    @click="logout"
                    class="app-header__logout"
                    type="link"
                  >
                    Wyloguj się
                  </a-button>
                </nav>
              </div>
            </a-drawer>
          </div>
        </a-col>
      </a-col>

      <a-col
        class="app-header__wrapper app-header__wrapper--desktop"
        :xs="0"
        :sm="24"
      >
        <a-col :xs="12">
          <div class="app-header__left">
            <router-link to="/">
              <div class="app-header__logo"></div>
            </router-link>
          </div>
        </a-col>
        <a-col :xs="12">
          <div class="app-header__right">
            <div v-if="!store.user.accessToken" class="app-header__nav">
              <nav>
                <router-link to="/contact">
                  <a-button type="link">
                    Kontakt
                  </a-button>
                </router-link>
                <router-link to="/login">
                  <a-button class="app-header__login" type="primary">
                    Zaloguj się
                  </a-button>
                </router-link>
              </nav>
            </div>
            <div v-if="store.user.accessToken" class="app-header__nav">
              <nav v-if="store.user.roles.includes('PATIENT')">
                <router-link to="/">
                  <a-button type="link">
                    Moje wizyty
                  </a-button>
                </router-link>
                <a-dropdown class="app-header__menu" :trigger="['click']">
                  <a
                    class="ant-dropdown-link"
                    @click="(e) => e.preventDefault()"
                  >
                    Mój profil <DownOutlined />
                  </a>
                  <template v-slot:overlay>
                    <a-menu>
                      <a-menu-item key="0">
                        <router-link to="/profile">
                          <a>Dane konta</a>
                        </router-link>
                      </a-menu-item>
                      <a-menu-item key="1">
                        <a class="app-header__logout" @click="logout"
                          >Wyloguj się</a
                        >
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </nav>
              <nav v-if="store.user.roles.includes('DOCTOR')">
                <a-button
                  @click="logout"
                  class="app-header__logout"
                  type="link"
                >
                  Wyloguj się
                </a-button>
              </nav>
            </div>
          </div>
        </a-col>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { store, actions } from "@/store";
import { DownOutlined, MenuOutlined } from "@ant-design/icons-vue";

export default {
  name: "AppHeader",
  components: {
    DownOutlined,
    MenuOutlined,
  },
  data() {
    return {
      visibleMobileMenu: false,
      store,
    };
  },
  methods: {
    changeRoute(path) {
      this.visibleMobileMenu = false;
      this.$router.push({ path });
    },
    logout() {
      this.$router.push({ name: "Login" });
      actions.setUser({});
      this.visibleMobileMenu = false;
      this.$http.defaults.headers.common = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.ant-dropdown-menu .ant-dropdown-menu-item a.app-header__logout,
.ant-dropdown-menu .ant-dropdown-menu-item a.app-header__logout:hover {
  color: $c6 !important;
}

.app-header__drawer {
  .app-header__nav {
    .ant-btn {
      display: block;
      color: $c1;
      margin: 12px 0;
    }
    .app-header__logout {
      color: $c6 !important;
    }
  }
}

.app-header {
  background-color: white;
  margin: 0 auto;
  max-width: $container-width;
  min-height: $header-height;
  padding: 0 10px;

  .app-header__wrapper {
    > .ant-col {
      height: 100%;
      > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    &--mobile {
      .app-header__left {
        a {
          margin: 0 auto;
        }
        .app-header__logo {
          background-position: center;
        }
        .app-header__hamburger {
          font-size: 2em;
          > span {
            vertical-align: 0.125em;
            color: $c1;
          }
        }
      }
    }
    .app-header__right {
      justify-content: flex-end;
    }
    .app-header__logo {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
      height: $header-height - 20px;
      @if $mode == GENERAL {
        background-image: url("~@/assets/images/logo-homedoctor-color.png");
        width: 94px;
      } @else if $mode == PZU {
        background-image: url("~@/assets/images/logo-pzu-zdrowie-horizontal.png");
        width: 113px;
      } @else if $mode == POLMED {
        background-image: url("~@/assets/images/logo-polmed.png");
        width: 200px;
      } @else if $mode == MONDIAL {
        background-image: url("~@/assets/images/logo-mondial.png");
        width: 181px;
      } @else if $mode == ALLIANZ {
        background-image: url("~@/assets/images/logo-allianz.png");
        width: 195px;
      } @else if $mode == EUROP_ASSISTANCE {
        background-image: url("~@/assets/images/logo-europ-assistance.png");
        width: 82px;
      } @else if $mode == CMP {
        background-image: url("~@/assets/images/logo-cmp.png");
        width: 89px;
      } @else if $mode == GLOBAL_ASSISTANCE {
        background-image: url("~@/assets/images/logo-global-assistance.png");
        width: 140px;
      } @else if $mode == SIGNAL_IDUNA {
        background-image: url("~@/assets/images/logo-signal-iduna.png");
        width: 200px;
      }
    }
    .app-header__nav {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      nav {
        display: contents;
        .ant-btn-link {
          color: $g1;
          font-weight: normal;
        }

        .app-header__logout,
        .app-header__logout:hover {
          color: $c6;
        }

        .app-header__login {
          margin-left: 3vw;
        }
        .app-header__menu {
          color: $g1;
          padding-left: 3vw;
          //temporary solution
          height: 40px;
          &.ant-dropdown-open {
            color: $g4;
          }
        }
      }
    }
  }
}
</style>
